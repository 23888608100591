import { useEffect, useState } from "react";
import { digitalBenchmarkReportDataStore } from "../../../stores/digital_benchmark_report_data_store";
import LoadingWithText from "../../common/loading_text";
import { toJS } from "mobx";
import Highcharts from 'highcharts';
import { observer } from "mobx-react";
import { digitalTranformationTypeMappingReverse } from "./../utils";


const EmployeePercentageChart = ({requestData, digitalType=null}) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);
    const title = digitalType === null ? `${targetCompanyName}: Digital Employee Percentage and Count Over Time` : `${targetCompanyName}: ${digitalTranformationTypeMappingReverse[digitalType]} Employee Percentage and Count Over Time`;
    const divId = digitalType === null ? 'employee-percentage-chart' : `employee-percentage-chart-${digitalType}`;
    const pctChartDivId = digitalType === null ? 'percentage-digital-chart-container' : `percentage-digital-chart-container-${digitalType}`;
    const headcountChartDivId = digitalType === null ? 'total-headcount-chart-container' : `total-headcount-chart-container-${digitalType}`;


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            let headcountHistoryData = [];
            if (digitalType !== null) {
                await digitalBenchmarkReportDataStore.fetchHeadcountHistoryByDigitalTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                headcountHistoryData = digitalBenchmarkReportDataStore.headcountHistoryByDigitalTypeData[targetCompanyId];
            }
            else {
                await digitalBenchmarkReportDataStore.fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                headcountHistoryData = digitalBenchmarkReportDataStore.headcountHistoryData[targetCompanyId];
            }

            if (headcountHistoryData !== null) {
                setData(toJS(headcountHistoryData));
                console.log('headcountHistoryData', toJS(headcountHistoryData));
                setDataLoading(false);
            }
            else {
                console.log('headcountHistoryData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);

    useEffect(() => {
        const drawPercentageDigitalChart = (data) => {
            let seriesData = [];
            if (digitalType !== null) {
                data = data.filter(d => d.digital_transformation_type === digitalType);

                seriesData = [{
                    name: '% Digital of Target',
                    data: data.map(d => [d.the_date, parseFloat((d.pct_digital_type * 100).toFixed(2))]),
                    type: 'spline',
                    color: 'blue',
                }, {
                    name: '% Median benchmark',
                    data: data.map(d => [d.the_date, parseFloat((d.median_pct_digital_type_benchmark * 100).toFixed(2))]),
                    type: 'spline',
                    dashStyle: 'ShortDash',
                    color: 'blue'
                }, {
                    name: '% Top Quartile benchmark',
                    data: data.map(d => [d.the_date, parseFloat((d.top_quartile_pct_digital_type_benchmark * 100).toFixed(2))]),
                    type: 'spline',
                    dashStyle: 'Dot',
                    color: 'blue'
                }];
            }
            else {
                seriesData = [{
                    name: '% Digital of Target',
                    data: data.map(d => [d.the_date, parseFloat((d.pct_digital * 100).toFixed(2))]),
                    type: 'spline',
                    color: 'blue',
                }, {
                    name: '% Median benchmark',
                    data: data.map(d => [d.the_date, parseFloat((d.median_pct_digital_benchmark * 100).toFixed(2))]),
                    type: 'spline',
                    dashStyle: 'ShortDash',
                    color: 'blue'
                }, {
                    name: '% Top Quartile benchmark',
                    data: data.map(d => [d.the_date, parseFloat((d.top_quartile_pct_digital_benchmark * 100).toFixed(2))]),
                    type: 'spline',
                    dashStyle: 'Dot',
                    color: 'blue'
                }]
            }

            
            
            Highcharts.chart(pctChartDivId, {
                chart: {
                    zoomType: 'xy',
                    type: 'spline',
                    height: '25%',
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                },
                tooltip: {
                    shared: true,
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    type: 'datetime',
                    tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                    labels: {
                        style: {
                            fontSize: '20px'
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: digitalType === null ? 'Percentage Digital' : `Percentage ${digitalTranformationTypeMappingReverse[digitalType]}`,
                        style: {
                            fontSize: '20px'
                        },

                    },
                    labels: {
                        format: '{value}%',
                        style: {
                            fontSize: '20px'

                        },
                    },
                },
                legend: {   
                    enabled: true,
                    itemStyle: {
                        fontSize: '20px'
                    },
                },
                series: seriesData,
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    shared: true,
                    crosshairs: true, 
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    },
                },
                credits: {
                    enabled: false
                },
            });

        };

        const drawTotalHeadcountChart = (data) => {
            if (digitalType !== null) {
                data = data.filter(d => d.digital_transformation_type === digitalType);
            }

            Highcharts.chart(headcountChartDivId, {
                chart: {
                    zoomType: 'xy',
                    type: 'area',
                    height: '20%',
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                },
                tooltip: {
                    shared: true,
                    pointFormatter: function() {
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Highcharts.numberFormat(this.y, 0, undefined, ',') + '</b><br/>';
                    }
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    type: 'datetime',
                    tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                    labels: {
                        style: {
                            fontSize: '20px'
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: digitalType === null ? 'Digital Employee Headcount' : `${digitalTranformationTypeMappingReverse[digitalType]} Employee Headcount`,
                        style: {
                            fontSize: '20px'
                        },
                    },
                    // format labels as Ks or Ms
                    labels: {
                        formatter: function() {
                            if (this.value >= 1000000) {
                                return (this.value / 1000000) + 'M';
                            }
                            if (this.value >= 1000) {
                                return (this.value / 1000) + 'K';
                            }
                            return this.value;
                        },
                        style: {
                            fontSize: '20px'
                        },
                    },
                },
                legend: {   
                    enabled: false,
                    itemStyle: {
                        fontSize: '20px'
                    },
                },
                series: [{
                    name: 'Total Headcount',
                    data: data.map(d => [d.the_date, d.company_headcount_total]),
                    type: 'area',
                    color: 'lightgray',
                }],
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    },
                },
                credits: {
                    enabled: false
                },
            });

        };

        if (data !== null) {
            const filteredData = data.filter(d => d.linkedin_company_id === targetCompanyId);
            drawPercentageDigitalChart(filteredData);
            drawTotalHeadcountChart(filteredData);
        }
    }, [data]);


    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {title} </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting headcount data...', 'Finished']} interval={5000} />
                    </div>
                )
                :
                (
                <>
                    <div id={divId}>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id={pctChartDivId} />
                        </div>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id={headcountChartDivId} />
                        </div>
                    </div>
                </>
                )
            }
        </div>
    );

};

export default EmployeePercentageChart;