import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { digitalBenchmarkReportDataStore } from "../../../stores/digital_benchmark_report_data_store";
import Highcharts from 'highcharts';
import LoadingWithText from "../../common/loading_text";
import { digitalTranformationTypeMappingReverse } from "./../utils";


const TargetToPeerDigitalAttritionChart = ({requestData, digitalType=null}) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const title = digitalType === null ? `Target-to-Peer Set Comparison: Digital Employee Attrition (Rolling Last 12 months) Over Time` : `Target-to-Peer Set Comparison: ${digitalTranformationTypeMappingReverse[digitalType]} Employee Attrition (Rolling Last 12 months) Over Time`;
    const divId = digitalType === null ? 'digital-attrition-target-peer-chart' : `digital-attrition-target-peer-chart-${digitalType}`;
    const chartContainerId = digitalType === null ? 'attrition-chart-container' : `attrition-chart-container-${digitalType}`;


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            let headcountHistoryData = [];
            if (digitalType !== null) {
                await digitalBenchmarkReportDataStore.fetchHeadcountHistoryByDigitalTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                headcountHistoryData = digitalBenchmarkReportDataStore.headcountHistoryByDigitalTypeData[targetCompanyId];
            }
            else {
                await digitalBenchmarkReportDataStore.fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                headcountHistoryData = digitalBenchmarkReportDataStore.headcountHistoryData[targetCompanyId];
            }

            if (headcountHistoryData !== null) {
                setData(toJS(headcountHistoryData));
                console.log('headcountHistoryData', toJS(headcountHistoryData));
                setDataLoading(false);
            }
            else {
                console.log('headcountHistoryData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);

    useEffect(() => {
        const drawAttritionChart = (data) => {
            // Extract unique company names
            const categories = [...new Set(data.map(item => item.name))];

            // Prepare the series data
            const seriesData = [];
            categories.forEach(category => {
                let dataForCategory = data.filter(d => d.name === category);
                if (digitalType !== null) {
                    dataForCategory = dataForCategory.filter(d => d.digital_transformation_type === digitalType);
                }
                const seriesItems = [];
                dataForCategory.forEach(item => {
                    seriesItems.push([item.the_date, parseFloat((item.attrition_rate_prev_12_mo * 100).toFixed(1))]);
                });

                if (category === targetCompanyName) {
                    seriesData.push({
                        name: category,
                        data: seriesItems,
                        type: 'spline',
                        color: 'black',
                        lineWidth: 4,
                    });
                }
                else {
                    seriesData.push({
                        name: category,
                        data: seriesItems,
                        type: 'spline',
                    });
                }
            });

            Highcharts.chart(chartContainerId, {
                chart: {
                    zoomType: 'xy',
                    type: 'spline',
                    height: '25%',
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                },
                tooltip: {
                    shared: true,
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    type: 'datetime',
                    tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                    labels: {
                        style: {
                            fontSize: '20px',
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: digitalType === null ? 'LTM Attrition Rate (Digital Roles)' : `LTM Attrition Rate (${digitalTranformationTypeMappingReverse[digitalType]})`,
                        style: {
                            fontSize: '20px',
                        },
                    },
                    labels: {
                        format: '{value}%',
                        style: {
                            fontSize: '20px',
                        },
                    },
                },
                legend: {   
                    enabled: true,
                    itemStyle: {
                        fontSize: '20px',
                    },
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    floating: false,
                },
                series: seriesData,
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    shared: true,
                    crosshairs: true, 
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    },
                },
                credits: {
                    enabled: false
                },
            });

        };

        if (data !== null) {
            drawAttritionChart(data);
        }

    }, [data]);

    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {title} </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting headcount data...', 'Finished']} interval={5000} />
                    </div>
                )
                :
                (
                <>
                    <div id={divId}>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id={chartContainerId} />
                        </div>
                    </div>
                </>
                )
            }
        </div>
    );

};

export default TargetToPeerDigitalAttritionChart;
